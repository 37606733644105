import React from 'react'
import { Line, YAxis } from 'recharts'
import { Chart, ChartWrapper } from '../../common'

const info = `This tile shows the number of executive proposals and polls that have been created over time. This allows governance to judge how the rate of increase has changed over time, which can be used as an approximation for the time-cost of governance. <br><br> Executive Votes total is calculated by counting each unique contract address that a MKR holder has voted for and displaying the count of those unique contract addresses at each date on the graph. <br><br> Polls total is calculated by counting the number of CreatePollAction events generated by the PollingEmiter contract and displaying that count value at each date on the graph.
`
const links = [
  {
    title: 'MakerDAO Governance Graph',
    uri: 'https://thegraph.com/explorer/subgraph/protofire/makerdao-governance?query=Executive%20Votes%20and%20Polls',
  },
]

const VotesVsPollsChart = props => {
  const { wrapperProps, modalProps } = props
  return (
    <ChartWrapper markdown info={info} links={links} {...wrapperProps}>
      <Chart {...modalProps}>
        <YAxis datakey="countPolls" />
        <Line
          dataKey="countVotes"
          dot={false}
          isAnimationActive={modalProps.data ? false : true}
          name={'Executive Votes'}
          stroke="#9227a0"
          strokeWidth={2}
          type="monotone"
        />
        <Line
          dataKey="countPolls"
          dot={false}
          isAnimationActive={modalProps.data ? false : true}
          name={'Polls'}
          stroke="#a06d27"
          strokeWidth={2}
          type="monotone"
        />
      </Chart>
    </ChartWrapper>
  )
}

export default VotesVsPollsChart
